







import {Vue, Component, Watch} from 'vue-property-decorator'

import {URLS} from '@/const'

const secret = parseInt(process.env.VUE_APP_SECRET_STATION_MAP as string) || 0

@Component({
    name: 'StationMap',
})
export default class StationMap extends Vue {
    iFrameSrc = ''

    @Watch('$dbiStore.state.homeStation', {immediate: true})
    calcFrameSrc(): void {
        const key = btoa((Math.floor(Date.now() / 60000) ^ secret).toString())
        const url = `${URLS.STATION_MAP}?key=${key}&appId=PIA-mobile`
        const {homeStation} = this.$dbiStore.state
        this.iFrameSrc = homeStation ? `${url}&evanr=${homeStation.eva}` : url
    }
}
